import { useSpecialClassHandler } from "../Scripts/hooks/useSpecialClassHandler";

import RegStep1 from "../Components/Registration/RegStep1";
import RegStep2 from "../Components/Registration/RegStep2";

import AnimWave from "../Components/Animated/AnimWave";
import Slideshow, { useSlideshowController } from "../Components/General/Slideshow/Slideshow";
import { useFormHandler } from "../Scripts/hooks/useFormHandler";
import { useState } from "react";
import { registerReq } from "../Scripts/dataHandler";

interface RegistrationData {
    teamName: string,
    email: string
}

function RegistrationPage() {

    useSpecialClassHandler()

    let [err, setErr] = useState("")
    let [validated, setValidated] = useState(false)

    let { /*formData,*/ formChange } = useFormHandler<RegistrationData>({
        teamName: "",
        email: ""
    }, undefined, false) //TODO: Implement basic input checks on formChange, with a slight delay to "detect" when the user has stopped typing or something

    const controller = useSlideshowController([
        <RegStep1 formChange={formChange} setValidated={setValidated} setErr={setErr}/>,
        <RegStep2 formChange={formChange} setValidated={setValidated} setErr={setErr}/>
    ], "slide-rightToLeft")

    const nextPage = () => {
        console.log(validated);
        if (validated) {
            setValidated(false)
            controller.nextPage()
        }
    }

    return ( <>
        <div className="container column centerAlign">
            <div className="elementSeries absPos centerAlign fadeIn">
                {/*<div style={{ "--duration": "2s"  } as React.CSSProperties} className="elementSeries fadeTopToBottom">
                    <h1 className="stay">Ty jsi se rozhodl se zaregistrovat?</h1>
                    <h1 className="stay">Skvělé!</h1>
                </div>

                <h1>Začněme pěkně od začátku</h1>*/}

                <div className="container centerAlign column stay">
                    <div style={{position: "absolute", "top": "45vh"}}>
                        <Slideshow controller={controller}/>
                    </div>

                    <div style={{position: "absolute", bottom: "20vh"}}>
                        <button onClick={nextPage}>Dále</button>
                        <h3>{err}</h3>
                        <input type="file" id="teamPfpSelector"/>
                        <button onClick={() => {registerReq({} as any,() => {})}}>test</button>
                    </div>

                    
                    <AnimWave/>
                </div> 

            </div>
        </div>
        </>
     );
}

export default RegistrationPage;