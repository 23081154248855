import { RegistrationData, StrIndexedObj } from "./misc";

const defaultUrl = "http://localhost:8080"

// Basic req functions
const sendReq = (opts: Object, url: string, endpoint: string, errMsgSetter?: Function) => {
    console.log(url+endpoint)
  return fetch(url + endpoint, opts)
    .then(async (res) => {
        console.log(res)
      let temp = await res.json();
      temp.status = res.status;
      return temp;
    })
    .catch((err) => {
      console.log(err);
      if (errMsgSetter) {errMsgSetter("Něco se pokazilo")}
    });
};

export const sendGetReq = async (endpoint: string, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "GET",
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};

export const sendPostReq = async (endpoint: string, body: StrIndexedObj, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    } 
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};




// Abstract req functions
export const registerReq = async (data: RegistrationData, setter: Function, errMsgSetter?: Function) => {
  
    data = {
        "teamName": "Abiteee",
    
        "motto": "Miluju smoulu!!!",
        "pfp": "",
    
        "primaryEmail": "uwu4212@gmail.com",
        "isTeacher": true,
        "teacherEmail": "uwu4212@gmail.com",
        "newsletter": true,
    
        "password": "ZabSe123!",
        "repeatPassword": "ZabSe123!",
    
        "members": [{
            "name": "bořek",
            "birthDate": "2024-01-01",
            "school": "b",
            "city": "Prague",
            "schoolYear": 1,
            "parentName": "borek velikananansky",
            "parentEmail": "borek@vavra.cz",
            "gdpr": 0
        }]
    }

  let res = await sendPostReq("/auth/register", data)

  if (res) {
    if (res.status != 200) {
      if (errMsgSetter) {errMsgSetter(res.msg)}
      return
    }

    setter(res.data)

    data.id = res.id

    uploadPfp(data)
  }
  return
}

const uploadPfp = async (teamData: RegistrationData) => {
    const fileInput = document.getElementById("teamPfpSelector") as HTMLInputElement;
    if (!fileInput.files) {return}

    const file = fileInput?.files[0];

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("pfp", file);
    formData.append("id", teamData.id)
    formData.append("teamName", teamData.teamName)

    try {
      const response = await fetch(defaultUrl+"/upload/uploadPfp", {
        method: "POST",
        body: formData
      });

      if (response.ok) {
        const result = await response.json();
        alert(result.message);
      } else {
        const error = await response.json();
        alert(error.error || 'Failed to upload image.')
      }
    } catch (error: any) {
      alert('Error uploading image: ' + error.message)
    }
}