import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Homepage from './Pages/Homepage';

import { Link } from 'react-router-dom';

import './Styles/main.css';
/*import RegistrationPage from './Pages/RegistrationPagePlaceholder';*/
import RegistrationPage from './Pages/RegistrationPage';

import "./Styles/specialClasses.css"
import { useSpecialClassHandler } from './Scripts/hooks/useSpecialClassHandler';
import ArchivePage from './Pages/ArchivePage';
import PageNotFound from './Pages/PageNotFound';

import Skibidi from './Pages/Skibidi'
import NavBar from './Components/General/NavBar';

import CookieConsent from "react-cookie-consent";
import Cookies from 'universal-cookie';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import TeamDetailsPage from './Pages/TeamDetailsPage';
import GameDetailsPage from './Pages/GameDetailsPage';
import { useEffect } from 'react';

function App() {
  let cookies = new Cookies()

  const acceptedCookie = cookies.get("acceptedCookie")

  useSpecialClassHandler() //TODO: Do I need to call this on every page?

  useEffect(() => {window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }},[]) // yupp

  return (
    <BrowserRouter>
      <CookieConsent
        location="top"
        buttonText="Souhlasím"
        cookieName="acceptedCookie"
        style={{ background: "#4695d6" }}
        buttonStyle={{ background: "#ffffff", color: "#4695d6", fontSize: "13px" }}
        expires={150}
      >
        Tato stránka používá c🍪🍪kies.{" "}
        <span style={{ fontSize: "10px" }}>Používáme je s cílem zajistit co možná nejlepší zážitek při návštěvě našich webových stránek a za účelem analýzy návštěvnosti. Informace o tom, jak náš web používáte, sdílíme se svými partnery pro analýzu. Partneři tyto údaje mohou zkombinovat s dalšími informacemi, které jste jim poskytli nebo které získali v důsledku toho, že používáte jejich služby. <Link to={"/zasadyOchranyOsobnichUdaju"} style={{color: "white"}}>Více informací zde.</Link></span>
      </CookieConsent>

      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/archiv" element={<ArchivePage/>}/>
        <Route path="/registrace" element={<RegistrationPage/>}/>
        <Route path="/tym" element={<TeamDetailsPage/>}/>
        <Route path="/hra" element={<GameDetailsPage/>}/>
        
        <Route path="/zasadyOchranyOsobnichUdaju" element={<PrivacyPolicyPage/>}/>

        <Route path="/skibidi" element={<Skibidi/>}/>

        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
