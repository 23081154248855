import MemberCard from "../Components/TeamDetailsPage/MemberCard";
import GoBack from "../Components/General/GoBack";
import { useEffect } from "react";

function TeamDetailsPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<div className="column centerAlign teamDetailsPage" style={{ marginTop: "7vh" }}>
        <GoBack link="/" />
        <h1>O nás</h1>
        <div className="column centerAlign">
            <MemberCard name={"Jiří Kryštof Jedlička"} desc={"Organizace, úlohy, komunikace"} img={require("../Assets/images/pfps/jkj.png")} />
            <MemberCard name={"David Bojko"} desc={"Organizace, úlohy, IT"} img={require("../Assets/images/pfps/db.png")} />
            <MemberCard name={"Vojtěch Štefek"} desc={"Organizace, komunikace"} img={require("../Assets/images/logos/transparent-white.png")} />
            <MemberCard name={"Karan J. S."} desc={"Úlohy"} img={require("../Assets/images/logos/transparent-white.png")} />
            <MemberCard name={"Jakub Mikeš"} desc={"Komunikace"} img={require("../Assets/images/logos/transparent-white.png")} />
            <MemberCard name={"Iryna Serykava"} desc={"Ilustrace"} img={require("../Assets/images/logos/transparent-white.png")} />
            <MemberCard name={"Anita Bíróczi a Lucie Musilová"} desc={"Testování úloh"} img={require("../Assets/images/pfps/abAlm.jpg")} />
            <br />
        </div>
    </div>);
}

export default TeamDetailsPage;
