import FAQ from "../General/FAQ/FAQ";

function FAQPage() {

    const questions = [
        {
            title: "Kde se soutěž koná?",
            desc: "Soutěž probíhá online, nejčastěji na školách účastníků. Lze ji ale řešit kdekoli, kde máte internetové připojení!"
        },
        {
            title: "Kolik času soutěž zabere?",
            desc: "Soutěž samotná trvá od 9:00 do 12:00 dopoledne, přičemž následné vyhodnocení trvá přibližně 15 minut. Zároveň si doporučujeme vyhradit cca. 10 minut na přihlášení do soutěžního prostředí a zajištění fungujícího technického zázemí před začátkem soutěže."
        },
        {
            title: "Může být v jednom týmu více žáků z jiných ročníků?",
            desc: "V jednom týmu mohou být i žáci napříč ročníky, každý člen však musí navštěvovat 6. až 9. třídu ZŠ (nebo odpovídající ročníky víceletých gymnázií). Zároveň je silně doporučeno, aby se členové týmů předem znali."
        },
        {
            title: "Vyplňují týmy řešení na jednom počítači, nebo je výhodnější, aby měl každý z týmu svůj počítač?",
            desc: "Z technických důvodů doporučujeme, aby týmy řešení vyplňovaly na jednom počítači. Pokud ale bude mít každý z členů týmu svůj počítač, výrazně mu to ulehčí práci při práci na jednotlivých úlohách, jelikož si bude moci lépe úlohy rozdělit."
        },
        {
            title: "Pokud bychom ze školy přihlásili více týmů, mají všechny týmy stejné otázky a ve stejném pořadí?",
            desc: "Ano, týmy budou mít stejné jak otázky, tak jejich pořadí."
        },
        {
            title: "Kolik týmů se může maximálně zaregistrovat z jedné školy?",
            desc: "Počet týmů z jedné školy nijak nelimitujeme."
        },
        {
            title: "Může být některý ze členů v jiných ročnících, než je 2. stupeň ZŠ?",
            desc: "V tuto chvíli toto není možné."
        },
        {
            title: "Kdy máte v plánu otevřít registrace na ročník soutěže 2025?",
            desc: "Otevřít registrace plánujeme začátkem kalendářního roku 2025."
        },
        {
            title: "Mám další otázku!",
            desc: "Kontaktujte nás na info@smoulasoutez.cz nebo na telefoním čísle +420 228 222 698."
        }
    ]

    return ( 
    <div className="faqPage column centerAlign">

        <h1>Často kladené dotazy</h1>

        <FAQ questions={questions}/>

    </div>
     );
}

export default FAQPage;