import { ChangeEventHandler, FormEvent } from "react";
import { schemas } from "../../Scripts/misc";

interface Props {
    formChange: ChangeEventHandler;
    setValidated: Function;
    setErr: Function;
}

function RegStep2({formChange, setValidated, setErr}: Props) {

    const check = (e: FormEvent<HTMLInputElement>) => {
        
        let result;

        switch(e.currentTarget.id) {
            case "email":
                result = schemas.email.validate(e.currentTarget.value)
                break
            case "teacherEmail":
                result = schemas.teacherEmail.validate(e.currentTarget.value)
                break
            default:
                result = {error: {details: [{message: "Něco se pokazilo"}]}}
        }

        if (result?.error) {
            setValidated(false)
            setErr(result.error.details[0].message)
        } else {
            setValidated(true)
            setErr("")
        }
    }

    return ( 
        <div style={{width: "50vw"}}>
            <h1>Jak tě máme kontaktovat?</h1>

                <div className="formGroup">
                    <h3>Kontaktní email (reset hesla, obecné informace):</h3>
                    <input type="text" id="email" maxLength={50} onChange={(e) => {check(e);formChange(e)}}/>
                </div>
                <div className="formGroup">
                    <h3>Učitelský email ():</h3>
                    <input type="text" id="teacherEmail" maxLength={50} onChange={(e) => {check(e);formChange(e)}}/>
                </div>

        </div>
     );
}

export default RegStep2;