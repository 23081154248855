import { ChangeEventHandler, FormEvent } from "react";
import { schemas } from "../../Scripts/misc";

interface Props {
    formChange: ChangeEventHandler;
    setValidated: Function;
    setErr: Function;
}

function RegStep1({formChange, setValidated, setErr}: Props) {

    const check = (e: FormEvent<HTMLInputElement>) => {
        let result = schemas.teamName.validate(e.currentTarget.value)
        console.log(result)
        if (result.error) {
            setValidated(false)
            setErr(result.error.details[0].message)
        } else {
            setValidated(true)
            setErr("")
        }
    }

    return ( 
        <div className="centerAlign column" style={{width: "50vw"}}>
            <h1>Jak se bude tvůj tým jmenovat?</h1>
            <input type="text" id="teamName" onChange={(e) => {check(e);formChange(e)}}/>
        </div>
     );
}

export default RegStep1;