import { Link } from "react-router-dom";
import "../../Styles/menu.css";

function Menu() {
  return (
    <div className="menuDiv">
    
    <div className="centerAlign column menu">
      <h1 style={{fontSize: "max(5vw,7vh)"}}>Kam dál?</h1>
      <div
        className="centerAlign btnColumn"
        style={{ marginTop: "2vh"}}
      >
        <div className="column centerAlign">
          <h3 style={{fontSize: "max(2vw,3vh)",width: "30vw"}}>Jak ŠMouLa vlastně probíhá?<br/></h3>
          <Link to={"/hra"} style={{color: "white"}}> 
          <div className="column card centerAlign">
            <h3 style={{fontSize: "max(2vw,2.3vh)"}}>Průběh hry</h3>
          </div>
          </Link>
        </div>

        <div className="column centerAlign">
        <h3 style={{fontSize: "max(2vw,3vh)",width: "30vw"}}>Pravidla soutěže ŠMouLa<br/>{"\n"}</h3>
          <a href="https://static.smoulasoutez.cz/kompletni_pravidla_SMouLa.pdf" target="_blank" rel="noreferrer" style={{color: "white"}}> 
          <div className="column card centerAlign">
            <h3 style={{fontSize: "max(2vw,2.3vh)"}}>Pravidla<br/>z roku 2024</h3>
          </div>
          </a>
        </div>

        <div className="column centerAlign">
        <h3 style={{fontSize: "max(2vw,3vh)",width: "30vw"}}>Kdo všechno se na ŠMouLovi podílel?</h3>  
        <Link to={"/tym"} style={{color: "white"}}> 
        <div className="column card centerAlign">
          <h3 style={{fontSize: "max(2vw,2.3vh)"}}>O nás</h3>
        </div>
        </Link>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Menu;
